import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { GiBread, GiSlicedBread } from "react-icons/gi"
import { IconContext } from "react-icons"

import { media } from "../styles/media"
import HeaderVisual from "../components/HeaderVisual"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const BreadA = () => (
  <IconContext.Provider value={{ size: "2em", color: "#E4D8B9" }}>
    <GiBread />
  </IconContext.Provider>
)

const BreadB = () => (
  <IconContext.Provider value={{ size: "2em", color: "#E4D8B9" }}>
    <GiSlicedBread />
  </IconContext.Provider>
)

const RecipeWrap = styled.div`
  padding: 50px 5% 30px;
  max-width: 1300px;
  margin: 0 auto;
  .recipeHeader {
    max-width: 1000px;
    margin: 0 auto 30px;
    display: flex;
    text-align: center;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 30px 0 30px;
    color: ${({ theme }) => theme.colors.primary.brown};
    svg {
      margin: 0 1.62em;
    }
  }
  .recipeBody {
    display: flex;
    flex-flow: row wrap;
    .recipeOne {
      padding: 2px;
      position: relative;
      height: 250px;
      .gatsby-image-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }
      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 2;
      }
      .textArea {
        position: absolute;
        bottom: 0;
        right: 0;
        color: white;
        padding: 0.62em;
        width: 100%;
        text-align: right;
        h4 {
          font-weight: bold;
          color: white;
          font-size: 0.9em;
          text-align: right;
          margin-bottom: 0.62em;
        }
        p {
          color: white;
          font-size: 0.8em;
        }
      }
    }
    .recipeOne:nth-child(6n) {
      width: 30%;
    }
    .recipeOne:nth-child(6n + 1) {
      width: 30%;
    }
    .recipeOne:nth-child(6n + 2) {
      width: 30%;
    }
    .recipeOne:nth-child(6n + 3) {
      width: 40%;
    }
    .recipeOne:nth-child(6n + 4) {
      width: 40%;
    }
    .recipeOne:nth-child(6n + 5) {
      width: 30%;
    }
  }
  ${() =>
    media.tablet(css`
      .recipeBody {
        width: 90%;
        margin: 0 auto 50px;
        .recipeOne:nth-child(2n) {
          width: 50%;
        }
        .recipeOne:nth-child(2n + 1) {
          width: 50%;
        }
      }
    `)}
  ${() =>
    media.sp(css`
      width: 100%;
      .recipeHeader {
        padding: 0;
        font-size: 0.9em;
        svg {
          margin: 0 0.32em;
        }
      }
      .recipeBody {
        width: 100%;
        .recipeOne {
          height: 200px;
          .textArea {
            h4 {
              font-size: 0.7em !important;
            }
            p {
              font-size: 0.7em !important;
            }
          }
        }
      }
    `)}
`

const RecipeBook = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiRecipePhotos: recipes,
    allStrapiBrands: { nodes: brands },
  } = data

  const createProductLink = product => {
    // const productSlug
    const matchedBrand = brands.find(brand => brand.strapiId === product?.brand)
    if (matchedBrand) {
      return (
        <Link
          to={`/${matchedBrand.slug}/${product.slug}/`}
          key={product.brand}
        />
      )
    }
  }

  const renderedRecipe = recipes.nodes.map((recipe, index) => (
    <div className="recipeOne" key={index}>
      <div className="textArea">
        <h4>{recipe.recipeName}</h4>
        <p>{recipe.recipeText}</p>
      </div>
      <Image fluid={recipe.recipeMedia.childImageSharp.fluid} />
      {createProductLink(recipe.product)}
    </div>
  ))

  return (
    <Layout>
      <SEO>
        <title>フランソア商品のアレンジメニュー | フランソア</title>
        <meta
          name="description"
          content="パン好きさんの投稿で創るFacebook連動型のパンを使った料理レシピ集"
        />
      </SEO>
      <HeaderVisual
        backImage={data.file.childImageSharp.fluid}
        mainTitle="フランソア商品のアレンジメニュー"
        mainText="パン好きさんが創ったフランソア商品の<br/>アレンジ・サンプル"
      />
      <RecipeWrap>
        <div className="recipeHeader">
          <BreadA />
          <p>
            メニューをクリックすると
            <br />
            使用したパンのページに移動します。
          </p>
          <BreadB />
        </div>
        <div className="recipeBody">
          {renderedRecipe}
          {/* {receipes.nodes.map(bird => (
            <div className="recipeOne">
              <div className="textArea">
                <h4>{bird.recipeName}</h4>
                <p>{bird.recipeText}</p>
              </div>
              <Image fluid={bird.recipeMedia.childImageSharp.fluid} />
              <Link to="#"></Link>
            </div>
          ))} */}
        </div>
      </RecipeWrap>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "recipeTitleVisual.png" }) {
      relativePath
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allStrapiRecipePhotos(sort: { fields: created_at, order: DESC }) {
      nodes {
        id
        recipeName
        recipeText
        product {
          slug
          brand
        }
        recipeMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allStrapiBrands {
      nodes {
        slug
        strapiId
      }
    }
  }
`

export default RecipeBook
